var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"LyXBw_kJKy8j7UV_cKiIE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

const THIRDPARTY_URLS = ["walletconnect.org", "walletconnect.com"];
const THIRDPARTY_MESSAGES = [
  "node-modules/@walletconnect",
  "node-modules/@wagmi",
  "Failed to execute 'transaction' on 'IDBDatabase'",
];

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,
  environment: APP_ENV !== "production" ? "development" : "production",
  enabled: APP_ENV !== "local",
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Example: Ignore errors from a specific third-party library
    if (error && error.message && THIRDPARTY_MESSAGES.includes(error.message)) {
      return null;
    }

    // Example: Ignore errors from specific URLs or patterns
    if (event.request && THIRDPARTY_URLS.includes(event.request.url)) {
      return null;
    }

    // Example: Only capture errors, not messages
    console.log("🚀 ~ beforeSend ~ event.level:", event.level);
    if (event.level === "info") {
      return null;
    }

    // If not ignored, return the event to send it to Sentry
    return event;
  },
});
